export default {
  setBlockPriceVariations: (state, payload) => {
    state.blockPriceVariations = payload;
  },
  updateBlockPriceVariation: (state, payload) => {
    const findInVariations = state.blockPriceVariations.findIndex(el => el.id === payload.id);

    if (findInVariations !== - 1) {
      state.blockPriceVariations.splice(findInVariations, 1, payload);
    }
  },
  removeBlockPriceVariation: (state, payload) => {
    const findInVariations = state.blockPriceVariations.findIndex(el => el.id === payload.id);

    if (findInVariations !== - 1) {
      state.blockPriceVariations.splice(findInVariations, 1);
    }
  },
  addBlockPriceVariation: (state, payload) => {
    state.blockPriceVariations.push(payload);
  },
};