export default {
  authStatus: (state) => state.status,
  getConnecting: (state) => state.connecting,
  currentUser: (state) => {
    if (! state.user) {
      state.user = JSON.parse(localStorage.getItem("user"));
    }
    return state.user;
  },
  currentClub: (state, dispatch) => {
    if (state.club === null || state.club === undefined) {
      dispatch("getSelectedClub").then(resp => {
        return resp.data;
      });
    }
    return state.club;
  },
  isMembershipsEnabled: (state) => {
    return state.club.kirolaReference && process.env.VUE_APP_ENABLED_CONTRIBUTION;
  },
};